export default {
  data: () => ({
    requiredRules: [v => !!v || "Campo necesario"],
    emailRules: [
      v => !!v || "Ingresa tu correo",
      v =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
          String(v).toLowerCase()
        ) || "Correo no valido"
    ],
    passwordRules: [
      v => !!v || "Campo necesario",
      v =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v) ||
        "Debe contener al menos 8 caracteres con: 1 Mayuscula, 1 Minuscula y un Número"
    ]
  })
};

const date = {
  data: () => ({
    requiredRules: [v => !!v || "Campo necesario"],
    monthRules: [
      v => !!v || "Ingresa el mes",
      v => /^0[1-9]|1[0-2]$/.test(v) || "Mes no valido"
    ],
    fullYearRules: [
      v => !!v || "Ingresa el mes",
      v => /^[1-2]\d{3}$/.test(v) || "Año no valido"
    ]
  })
};

const other = {
  data: () => ({
    requiredRules: [v => !!v || "Campo necesario"],
    termsRules: [v => !!v || "Debes aceptar para continuar"],
    phoneRules: [
      v => !!v || "Ingresa tu teléfono",
      v => /^\d{10}$/.test(v.replace(/[^\d]/gi, "")) || "Teléfono no valido"
    ],
    websiteRules: [
      v => !!v || "Ingresa tu sitio web",
      v =>
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/gi.test(
          v
        ) || "Página inválida"
    ],
    cardRules: [
      v => !!v || "Ingresa tu tarjeta",
      v => /^\d{16}$/.test(v.replace(/[^\d]/gi, "")) || "Tarjeta no valida"
    ],
    cvvRules: [
      v => !!v || "Ingresa el CVV",
      v => /^\d{3}$/.test(v) || "CVV no valido"
    ],
    zipRules: [
      v => !!v || "Ingresa el código postal",
      v => /^\d{5}$/.test(v) || "Código no valido"
    ]
  })
};

export { date, other };
